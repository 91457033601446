<template>
  <b-card>
    <b-overlay
      :show="!bankingProfiles"
      rounded="sm"
    >
      <div class="invoice-register-table pt-1">
        <div class="float-left ir-btn-wrapper">
          <b-button
            variant="primary"
            :to="{ name: 'billing-add-banking-profiles'}"
            class="ir-btn billing-top-btn"
          >
            Create Profile
          </b-button>
        </div>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          v-if="bankingProfiles"
          :columns="columns"
          :rows="bankingProfiles"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-2"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Common -->
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span v-if="props.column.field === 'action'">
              <a
                href="javascript:void(0)"
                class="text-success"
                @click.prevent="editBankingProfile(props.row.id)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="22"
                  class="align-middle"
                />
              </a>
              <a
                href="javascript:void(0)"
                class="text-danger"
                @click.prevent="deleteBankingProfile(props.row.id,props.row.profile_name)"
              >
                <feather-icon
                  icon="XSquareIcon"
                  size="22"
                  class="align-middle"
                />
              </a>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','15','20']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
    <b-modal
      id="export-modal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Export Table"
    >
      <div class="text-center">
        <b-button
          variant="primary"
          class="activation-btn"
        >
          To PDF
        </b-button><br>
        <b-button
          variant="primary"
          class="activation-btn mt-1 mb-1"
        >
          To Excel
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BPagination, BFormSelect, BCard, BOverlay, BButton, BBadge, BModal, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BOverlay,
    BButton,
    BCard,
    BModal,
    BFormGroup,
    BFormSelect,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  data() {
    return {
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'Profile Name',
          field: 'profile_name',
          sortable: false,
        },
        {
          label: 'Company',
          field: 'company_name',
          sortable: false,
        },
        {
          label: 'IBAN Code',
          field: 'iban_code',
          sortable: false,
        },
        {
          label: 'BIC/SWIFT',
          field: 'bic_swift',
          sortable: false,
        },
        {
          label: 'Bank Name',
          field: 'bank_name',
          sortable: false,
        },
        {
          label: 'Bank Address',
          field: 'bank_address',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Registered : 'light-success',
        External      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    bankingProfiles() {
      return this.$store.state.billing.allBankingProfiles
    },
  },
  created() {
    this.fetchAllBankingProfiles()
  },
  methods: {
    fetchAllBankingProfiles() {
      this.$store.dispatch('billing/fetchAllBankingProfiles')
        .catch(error => { console.log(error) })
    },
    deleteBankingProfile(id, name) {
      this.confirmationAlert(`Are you sure you want to delete ${name}?`)
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('billing/deleteBankingProfile', id)
              .then(() => {
                this.notify({
                  text: 'Bank profile has been successfully deleted!',
                  variant: 'success',
                })
              })
              .catch(error => { console.log(error) })
          }
        })
    },
    editBankingProfile(id) {
      this.$router.push(`/billing/edit-banking-profiles/${id}`)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker {
  width: 100% !important;
}

.invoice-register-table {
  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 30rem;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}
</style>
